<template>
  <div class="mb-2">
    <b-input-group>
      <b-form-input
      type="email"
      disabled
      class="rounded-pill"
      :class="{ 'left-rounded': email, 'input-pills': !email }"
      v-model="email"
      :state="emailIsValid && !emailExist"
      @blur="emailValidation()"
    />
    <!-- <b-input-group-append>
      <b-button
        v-show="email"
        size="sm"
        class="right-rounded"
        :variant="
          emailValidated && emailIsValid && !emailExist
            ? 'success'
            : 'outline-danger'
        "
        @click="updateEmail()"
        :disabled="emailValidated || !emailIsValid || emailExist"
        ><b v-if="emailExist">{{$t('Validations.email.emailInUse')}}</b>
        <b v-else-if="!emailIsValid">{{$t('Validations.email.incomplete')}}</b>
        <b v-else-if="emailValidated">{{$t('Validations.email.validated')}}</b>
        <b v-else>{{$t('Validations.email.validateNow')}}</b>
      </b-button>
    </b-input-group-append> -->
    <b-modal id="emailValidate" hideFooter>
      <template #modal-title>
        <b-icon icon="lock"/>
        <span class="text-primary pl-2">{{$t('Validations.email.emailValidation')}}</span>
      </template>
      <b-row class="p-4">
        <b-col cols="12">
          {{$t('Validations.email.securityText')}}
        </b-col>
      </b-row>
      <b-row class="px-4 pb-4">
        <b-col cols="4">
          <b-button
            class="w-100"
            pill
            variant="primary"
            @click="$bvModal.hide('emailValidate')"
            >{{$t('Validations.email.gotIt')}}</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </b-input-group>
  <div class="pl-2">
    <p class="text-danger" v-if="emailExist">{{$t('Validations.email.emailInUse')}}</p>
    <p class="text-danger" v-else-if="!emailIsValid">{{$t('Validations.email.incomplete')}}</p>
    <b-button v-else-if="!emailValidated" variant="link" @click="revalidateMobile">
      {{
        $t("Validations.email.validateNow")
      }}
    </b-button>
  </div>
</div>
</template>
<script>
// import axios from "axios";
export default {
  data() {
    return {
      email: this.$store.getters.user.Contact.Email?.toLowerCase(),
      emailExist: false,
      emailValidated: false,
      emailIsValid: false,
      errorMessage: null
    };
  },
  methods: {
    async updateEmail() {
      //Changes the user info in the DB
      this.$store.dispatch("updateUser", {
        ID: this.$store.state.user.user.UserId,
        Email: this.email,
        LanguageCode: this.$i18n.locale
      });
      this.confirmEmail();
    },
    confirmEmail() {
      this.$bvModal.show("emailValidate");
    },
    async emailValidation() {
      if (this.email) {
        this.emailIsValid = /\S+@\S+\.\S+/.test(this.email);
        this.emailValidated = this.$store.getters.user.IsEmailVerified;
      }
    }
  },
  beforeMount() {
    this.emailValidation();
  }
};
</script>
