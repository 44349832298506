<template>
  <div class="benefits rounded primary-address-form">
    <div class="col-md-12 py-4 m-auto">
      <div
        class="border-bottom d-flex justify-content-between align-items-center pb-2 mb-5"
      >
        <div>
          <h4 class="section-title">
            {{ $t("accountSettings.label.primaryContact") }}
          </h4>
        </div>
      </div>
      <b-card class="mx-auto my-3">
        <form @submit.prevent="register">
          <div class="">
            <div class="item">
              <label class="pl-2">{{ $t("contactInfo.label.firstName") }}</label>
              <b-form-input
                type="text"
                v-model="firstName"
                required
                class="input-pills"
                :placeholder="$t('contactInfo.label.firstName')"
              />
            </div>
            <div class="item">
              <label class="pl-2">{{ $t("contactInfo.label.lastName") }}</label>
              <b-form-input
                type="text"
                v-model="lastName"
                required
                :placeholder="$t('contactInfo.label.lastName')"
                class="input-pills"
              />
            </div>
            <div class="item">
              <label class="form-label pl-2">{{ $t("signIn.label.emailAddress") }}</label>
              <TheEmailValidation />
            </div>
            <div class="item" v-show="mobileValid">
              <label class="pl-2">{{ $t("signIn.label.phoneNumber") }}</label>
              <b-input-group class="row mx-0 py-1">
                <b-form-input
                  type="text"
                  v-model="mobile"
                  required
                  class="input-pills rounded-pill"
                  :placeholder="$t('signIn.label.phoneNumber')"
                  :state="mobileValid"
                  disabled
                />
                <!-- <b-button class="right-rounded" variant="success" disabled size="sm">
                  <b>{{ $t("Validations.mobile.validated") }}</b>
                </b-button> -->
              </b-input-group>
              <b-button class="pl-2 pt-0" variant="link" @click="revalidateMobile">{{
                $t("Validations.mobile.change")
              }}</b-button>
            </div>

            <div class="item" v-show="!mobileValid">
              <label class="form-label pl-2">{{ $t("signIn.label.phoneNumber") }}</label>
              <TheMobileValidation @onValidate="onValidateMobile" ref="mobileValidator" />
            </div>
          </div>
        </form>
        <div class="row">
          <b-col cols="6" class="pr-2">
            <b-button
              pill
              variant="secondary"
              class="w-100"
              :to="{ name: 'PrimaryContact' }"
              replace
              >{{ $t("global.button.cancel") }}
            </b-button>
          </b-col>
          <b-col cols="6" class="pl-2">
            <b-button
              pill
              variant="primary"
              class="w-100"
              @click="updateUser()"
              :disabled="!mobileValid"
            >
              {{ $t("global.button.save") }}
            </b-button>
          </b-col>
        </div></b-card
      >
    </div>
  </div>
</template>

<script>
import TheEmailValidation from "@/components/global/TheEmailValidation.vue";
import TheMobileValidation from "@/components/global/TheMobileValidation.vue";

export default {
  components: {
    TheEmailValidation,
    TheMobileValidation,
  },
  data() {
    return {
      firstName: this.$store.getters.user.Contact.FirstName,
      lastName: this.$store.getters.user.Contact.LastName,
      email: this.$store.getters.user.Contact.Email,
      mobile: this.$store.getters.user.Contact.Mobile,
      mobileCountryCode: null,
      mobileValid: this.$store.getters.user.IsMobileVerified,
      errorMassage: null,
    };
  },
  methods: {
    revalidateMobile() {
      this.mobileValid = false;
      this.$refs.mobileValidator.revalidateMobile();
    },
    onValidateMobile() {
      this.mobileValid = true;
      this.mobile = this.$store.getters.user.Contact.Mobile;
    },
    async updateUser() {
      //Changes the user info in the DB

      let countryCode;
      this.$store.getters.countries.forEach((element) => {
        if (this.country === element.Name) countryCode = element.Code;
      });
      this.$store.dispatch("updateUser", {
        ID: this.$store.state.user.user.UserId,
        FirstName: this.firstName,
        LastName: this.lastName,
        LanguageCode: this.$i18n.locale,
        CountryCode: countryCode,
        Mobile: this.mobile,
      });
      this.$router.push({ name: "PrimaryContact" });
    },
    onComplete(v) {
      this.verificationCode = v;
      this.mobileValidate();
    },
    handleMobile(mobile, isValid, c, countryCode) {
      this.mobile = mobile;
      this.mobileValid = isValid;
      this.mobileCountryCode = countryCode;
    },
  },
};
</script>

<style lang="scss">
.input-tel__input {
  border-radius: 0px !important;
  border-right: 0px !important;
}

.country-selector__input {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.select-country-container {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}
.primary-address-form {
  .card {
    width: 400px;
  }
  .section-title {
    font-weight: 600;
    font-size: 20px;
    font-family: "Inter", sans-serif !important;
    color: #111111;
    margin-bottom: 0;
  }
  .card-body {
    padding: 24px;
  }
  input {
    height: 48px;
    border-color: #d0d0d0 !important;
    box-shadow: none !important;
  }
  .item {
    margin-bottom: 24px;
  }
  .input-tel__input {
    border-left: 0 !important;
    &::placeholder {
      color: #acacac !important;
      font-size: 12px;
      font-family: "Inter", sans-serif !important;
    }
  }
  .country-selector {
    position: relative;
    z-index: 2 !important;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      height: 33px;
      background: #d0d0d0;
      width: 1px;
      top: 50%;
      transform: translateY(-50%);
    }
    svg {
      display: none !important;
    }
    &__label {
      display: none;
    }
    &__input {
      padding: 0 0 0 12px !important;
      border-right: 0 !important;
      font-size: 14px;
      color: #777 !important;
      font-family: "Inter", sans-serif !important;
    }
  }
  .btn:not(.btn-link) {
    height: 48px;
    font-size: 16px;
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    &.btn-secondary {
      border: 1px solid $light-blue;
      color: $light-blue;
      background: none;
    }
    &:hover {
      background: #00559d !important;
      color: white !important;
    }
  }
}
</style>
